import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import dayjs from 'dayjs';

import FormGroup from '@material-ui/core/FormGroup';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/CloseRounded';
import Button from '@material-ui/core/Button';

import BannerForm from '@components/pages/eventos/BannerForm';
import LiveAnimation from '@components/core/LiveAnimation';
import CheckIcon from '@material-ui/icons/Check';

import { IEvent } from '@models/event.interface';

import { SubscribeConference } from '@services/conferences';

import { useAuth } from '@hooks/auth';
import { useUser } from '@hooks/user';
import { useTracking } from '@hooks/tracking';
import { useSnackbar } from '@hooks/snackbar';

import { AvatarGroup } from '@material-ui/lab';
import { Avatar, Checkbox, Link } from '@material-ui/core';
import { getEventSpeakers } from '@services/squads';
import {
  Error,
  IconContainer,
  RowTerms,
  Terms,
  UncheckIcon,
  Title,
} from '@styles/pages/Eventos';
import * as Styled from './styles';

export interface IEventCards {
  liveEvents: IEvent[];
  futureEvents: IEvent[];
  liveSpeakers: any[];
  futureSpeakers: any[];
  subscribeConferenceSuccess?: (slug: string) => void;
  onClickShare?: (eventSlug: string) => void;
}

export interface IHideText {
  text: string;
  maxChar?: number;
}

const defaultMax = 100;

const hideText = ({ text, maxChar }: IHideText) => {
  const maxCount = maxChar || defaultMax;
  if (text) {
    return (
      text.substring(0, maxCount).trim() +
      (text.length <= maxCount ? '' : '...')
    );
  }
  return '';
};

const EventCards: React.FC<IEventCards> = ({
  liveEvents = [],
  futureEvents = [],
  liveSpeakers = [],
  futureSpeakers = [],
}) => {
  const [actualEvent, setActualEvent] = useState<IEvent>({} as IEvent);
  const { showSnackbar } = useSnackbar();
  const [error, setError] = useState(false);
  const [base64, setBase64] = useState('');

  const { user, userEvents, updateUserEvents } = useUser();
  const { setShowModalLogin } = useAuth();
  const { track } = useTracking();

  const [userSpeakers, setUserSpeakers] = useState([]);
  const [open, setOpen] = useState(false);

  const handleOpen = (event: IEvent) => {
    setOpen(true);
    setActualEvent(event);
    track('Secure your place - Events', {
      eventSlug: event.slug,
    });
  };

  const handleClose = () => {
    setOpen(false);
    track('Close modal registration event', {
      eventSlug: actualEvent.slug,
    });
  };

  const router = useRouter();

  const [state, setState] = useState({
    checked: true,
  });

  useEffect(() => {
    const loadUserSpeakers = async () => {
      let speakers = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const event of userEvents) {
        if (event) {
          // eslint-disable-next-line no-await-in-loop
          const speaker = await getEventSpeakers(event.id);
          speakers = [...speakers, speaker];
        }
      }
      setUserSpeakers(speakers);
    };

    loadUserSpeakers();
  }, [userEvents]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  useEffect(() => {
    const { email } = user;
    const { firstName } = user;
    const { lastName } = user;
    const userSubscriptionBase64 = JSON.stringify({
      email,
      surname: lastName,
      firstname: firstName,
      createUserLandingPage: false,
      linkResetPassword: '',
    });

    const encode = Buffer.from(userSubscriptionBase64).toString('base64');

    setBase64(encode);
  }, [user]);

  const handleSubscribeConference = async () => {
    if (!state.checked) {
      setError(true);
      return;
    }
    if (user?._id) {
      try {
        track('event-subscribe-conference-button-clicked', {
          eventSlug: actualEvent.slug,
        });
        if (state.checked) {
          track('Click button end registration', {
            eventSlug: actualEvent.slug,
          });
          await SubscribeConference.subscribeConference({
            eventID: actualEvent.id,
            userID: user._id,
          });

          router.push(
            `${process.env.NEXT_PUBLIC_APP}/eventos/${actualEvent.slug}?lead=${base64}`,
          );
          await SubscribeConference.updateHubspotProps(user, actualEvent.slug);
          updateUserEvents(actualEvent);
          showSnackbar({
            message: 'Parabéns ! \n Sua inscrição foi CONFIRMADA com sucesso !',
            type: 'success',
          });
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      const displayModalInSmallSize = true;
      const showSignUp = false;
      setShowModalLogin(true, showSignUp, displayModalInSmallSize);
    }
  };

  const handleAccessEvent = (event: IEvent) => {
    track('Access the event', {
      eventSlug: event.name,
    });
    window.open(`${process.env.NEXT_PUBLIC_CONFERENCES}/${event.slug}`);
  };

  return (
    <>
      <Styled.Container>
        {liveEvents.length === 0 &&
          futureEvents.length === 0 &&
          userEvents.length === 0 &&
          user._id &&
          'Você ainda não fez cadastro em nenhum evento'}
        {liveEvents.length > 0 && (
          <div style={{ width: '100%' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                paddingBottom: '20px',
              }}
            >
              <LiveAnimation />
              <Title className="event-card-title">Acontecendo agora</Title>
            </div>
            <>
              {liveEvents.map((event, eventIndex) => (
                <Styled.CardLink key={event.id}>
                  <Styled.Img
                    src={
                      event?.platformCard
                        ? event?.platformCard
                        : event?.imageCard
                    }
                    alt={event?.name}
                    width="250px"
                    height="284px"
                    objectFit="cover"
                    quality="100"
                  />

                  <Styled.CardLinkGroup>
                    <h3>
                      {hideText({
                        text: event.name || '',
                        maxChar: 40,
                      })}
                    </h3>
                    <h4>
                      {hideText({
                        text: event.description || '',
                        maxChar: 98,
                      })}
                    </h4>
                    <h5>
                      {dayjs(event.startedAt).format('DD/MM')}
                      {' a '}
                      {dayjs(event.endedAt).format('DD/MM')}
                      {', das '}
                      {dayjs(event.startedAt).format('HH:mm')}
                      {' às '}
                      {dayjs(event.endedAt).format('HH:mm')}
                    </h5>

                    {liveSpeakers.length > eventIndex &&
                      liveSpeakers[eventIndex].length > 0 && (
                        <Styled.SpeakersInfo>
                          <AvatarGroup max={3}>
                            {liveSpeakers[eventIndex].map(speaker => (
                              <Avatar
                                style={{ zIndex: 0 }}
                                alt={speaker?.speakerProfile?.name}
                                src={speaker?.speakerProfile?.imageProfile}
                                key={speaker?.speakerProfile?.id}
                              />
                            ))}
                          </AvatarGroup>
                          <Styled.SpeakersName>
                            {liveSpeakers[eventIndex]
                              .map(speaker => {
                                if (
                                  !speaker.speakerProfile ||
                                  !speaker.speakerProfile.name
                                )
                                  return '';
                                return speaker.speakerProfile.name;
                              })
                              .join(', ')}
                          </Styled.SpeakersName>
                        </Styled.SpeakersInfo>
                      )}

                    <Styled.CardLinkGroupButtons>
                      {!userEvents.find(
                        userEvent => userEvent?.slug === event?.slug,
                      ) ? (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleOpen(event)}
                        >
                          Garanta sua vaga
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleAccessEvent(event)}
                        >
                          Acesse o evento
                        </Button>
                      )}
                      <Styled.ShowDetails>
                        <Link
                          href={`${process.env.NEXT_PUBLIC_APP}/eventos/detalhes/${event.slug}`}
                        >
                          Ver detalhes {'>'}
                        </Link>
                      </Styled.ShowDetails>
                    </Styled.CardLinkGroupButtons>
                  </Styled.CardLinkGroup>
                </Styled.CardLink>
              ))}
            </>
          </div>
        )}
        {futureEvents.length > 0 && (
          <div style={{ width: '100%' }}>
            <Title className="eventCardTitle">Inscrições Abertas</Title>
            <Styled.CardContainer>
              {futureEvents.map((event, eventIndex) => {
                const BannerFormComponent = BannerForm[event.slug] as React.FC;

                return BannerFormComponent ? (
                  <BannerFormComponent key={event.id} />
                ) : (
                  <Styled.CardLink key={event.id}>
                    <Styled.Img
                      src={
                        event?.platformCard
                          ? event?.platformCard
                          : event?.imageCard
                      }
                      alt={event?.name}
                      width="250px"
                      height="284px"
                      objectFit="cover"
                      quality="50"
                    />

                    <Styled.CardLinkGroup>
                      <h3>
                        {hideText({
                          text: event.name || '',
                          maxChar: 40,
                        })}
                      </h3>
                      <h4>
                        {hideText({
                          text: event.description || '',
                          maxChar: 98,
                        })}
                      </h4>
                      <h5>
                        {dayjs(event.startedAt).format('DD/MM')}
                        {' a '}
                        {dayjs(event.endedAt).format('DD/MM')}
                        {', das '}
                        {dayjs(event.startedAt).format('HH:mm')}
                        {' às '}
                        {dayjs(event.endedAt).format('HH:mm')}
                      </h5>

                      {futureSpeakers.length > eventIndex &&
                        futureSpeakers[eventIndex].length > 0 && (
                          <Styled.SpeakersInfo>
                            <AvatarGroup max={3}>
                              {futureSpeakers[eventIndex].map(speaker => (
                                <Avatar
                                  style={{ zIndex: 0 }}
                                  alt={speaker?.speakerProfile?.name}
                                  src={speaker?.speakerProfile?.imageProfile}
                                  key={speaker?.speakerProfile?.id}
                                />
                              ))}
                            </AvatarGroup>
                            <Styled.SpeakersName>
                              {futureSpeakers[eventIndex]
                                .map(speaker => {
                                  if (
                                    !speaker.speakerProfile ||
                                    !speaker.speakerProfile.name
                                  )
                                    return '';
                                  return speaker.speakerProfile.name;
                                })
                                .join(', ')}
                            </Styled.SpeakersName>
                          </Styled.SpeakersInfo>
                        )}

                      <Styled.CardLinkGroupButtons>
                        {!userEvents.find(
                          userEvent => userEvent?.slug === event?.slug,
                        ) ? (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleOpen(event)}
                          >
                            Garanta sua vaga
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleAccessEvent(event)}
                          >
                            Acesse o evento
                          </Button>
                        )}
                        <Styled.ShowDetails>
                          <Link
                            href={`${process.env.NEXT_PUBLIC_APP}/eventos/detalhes/${event.slug}`}
                          >
                            Ver detalhes {'>'}
                          </Link>
                        </Styled.ShowDetails>
                      </Styled.CardLinkGroupButtons>
                    </Styled.CardLinkGroup>
                  </Styled.CardLink>
                );
              })}
            </Styled.CardContainer>
          </div>
        )}

        {userEvents.length > 0 && (
          <Styled.ContainerRegistered style={{ width: '100%' }}>
            <Title className="eventCardTitle">Eventos já Inscritos</Title>
            <Styled.CardContainer>
              {userEvents.map((event, eventIndex) => {
                const BannerFormComponent = BannerForm[event?.slug] as React.FC;
                let eventImageCard;

                if (event?.platformCard || event?.imageCard) {
                  eventImageCard = event.platformCard
                    ? event.platformCard
                    : event.imageCard;
                } else {
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  eventImageCard =
                    'https://startse-uploader.s3.us-east-2.amazonaws.com/prod/squads/event/platform-card/5ee6d76538ed941b74c4fe90.png?v=d030b05e-176d-4d35-a7db-5538c1253385';
                }

                return BannerFormComponent ? (
                  <BannerFormComponent
                    key={event?.id ? event?.id : eventIndex}
                  />
                ) : (
                  <>
                    {event && (
                      <Styled.CardLink key={event?.id ? event?.id : eventIndex}>
                        <Styled.Img
                          src={eventImageCard}
                          alt={event?.name}
                          width="250px"
                          height="284px"
                          objectFit="cover"
                          quality="50"
                        />

                        <Styled.CardLinkGroup>
                          <h3>
                            {hideText({
                              text: event?.name || '',
                              maxChar: 40,
                            })}
                          </h3>
                          <h4>
                            {hideText({
                              text: event?.description || '',
                              maxChar: 98,
                            })}
                          </h4>
                          <h5>
                            {dayjs(event?.startedAt).format('DD/MM')}
                            {' a '}
                            {dayjs(event?.endedAt).format('DD/MM')}
                            {', das '}
                            {dayjs(event?.startedAt).format('HH:mm')}
                            {' às '}
                            {dayjs(event?.endedAt).format('HH:mm')}
                          </h5>

                          {userSpeakers.length > eventIndex &&
                            userSpeakers[eventIndex].length > 0 && (
                              <Styled.SpeakersInfo>
                                <AvatarGroup max={3}>
                                  {userSpeakers[eventIndex].map(speaker => (
                                    <Avatar
                                      style={{ zIndex: 0 }}
                                      alt={speaker?.speakerProfile?.name}
                                      src={
                                        speaker?.speakerProfile?.imageProfile
                                      }
                                      key={speaker?.speakerProfile?.id}
                                    />
                                  ))}
                                </AvatarGroup>
                                <Styled.SpeakersName>
                                  {userSpeakers[eventIndex]
                                    .map(speaker => {
                                      if (
                                        !speaker.speakerProfile ||
                                        !speaker.speakerProfile.name
                                      )
                                        return '';
                                      return speaker.speakerProfile.name;
                                    })
                                    .join(', ')}
                                </Styled.SpeakersName>
                              </Styled.SpeakersInfo>
                            )}

                          <Styled.CardLinkGroupButtons
                            style={{ justifyContent: 'flex-end' }}
                          >
                            <Link
                              href={`${process.env.NEXT_PUBLIC_APP}/eventos/detalhes/${event.slug}`}
                            >
                              Ver detalhes {'>'}
                            </Link>
                          </Styled.CardLinkGroupButtons>
                        </Styled.CardLinkGroup>
                      </Styled.CardLink>
                    )}
                  </>
                );
              })}
            </Styled.CardContainer>
          </Styled.ContainerRegistered>
        )}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Styled.BoxWrapper>
            <Styled.ModalLogo>
              <img
                src="https://startse-landings.s3.us-east-2.amazonaws.com/logos/startse-logo-s.svg"
                alt="Logo StartSe"
              />
              <Styled.CloseButton onClick={handleClose}>
                <CloseIcon fontSize="default" />
              </Styled.CloseButton>
            </Styled.ModalLogo>
            <FormGroup row>
              <RowTerms>
                <Checkbox
                  checked={state.checked}
                  onChange={handleChange}
                  name="checked"
                  color="primary"
                  checkedIcon={
                    <IconContainer>
                      <CheckIcon style={{ color: '#FFF' }} />
                    </IconContainer>
                  }
                  icon={<UncheckIcon />}
                />
                <Terms>
                  Eu concordo com os{' '}
                  <Link
                    target="_blank"
                    href={`${process.env.NEXT_PUBLIC_CAMPAIGN_REGULATION_URI}/politica-de-privacidade`}
                  >
                    termos
                  </Link>{' '}
                  do {actualEvent.name}
                </Terms>
              </RowTerms>
              {error && (
                <Error>
                  * É necessário concordar com os termos do evento para se
                  inscrever
                </Error>
              )}
              <Styled.CenterButton>
                <Button
                  style={{ borderRadius: '36px' }}
                  variant="contained"
                  color="primary"
                  onClick={() => handleSubscribeConference()}
                >
                  Finalizar inscrição
                </Button>
              </Styled.CenterButton>
            </FormGroup>
          </Styled.BoxWrapper>
        </Modal>
      </Styled.Container>
    </>
  );
};

export default EventCards;
